var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { SnmpAuthParams, GetAuthenticatedScanAgentsList, AuthenticationParamsSerializationType, WindowsAuthParams, AssessmentType, WindowsAuthType } from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { RegExpService } from '@wcd/shared';
import { Paris } from '@microsoft/paris';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';
var AssessmentJobJobDetailsStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobJobDetailsStepComponent, _super);
    function AssessmentJobJobDetailsStepComponent(paris, assessmentJobService, changeDetection) {
        var _this = _super.call(this) || this;
        _this.paris = paris;
        _this.assessmentJobService = assessmentJobService;
        _this.changeDetection = changeDetection;
        _this.snmpAuthTypeForm = new NgForm([], []);
        _this.windowsAuthTypeForm = new NgForm([], []);
        _this.MessageBarType = MessageBarType;
        _this.agentNamesList = [];
        _this.agentsList = [];
        _this.RegExpService = RegExpService;
        _this.isCredentialEditMode = false;
        _this.targetChanged$ = new Subject();
        _this.isWindowsScan = function () { return _this.data.assessmentJob.scanType === AssessmentType.WindowsAssessment; };
        _this.displaySnmpCredentialComponent = function () { return _this.data.assessmentJob.auth && _this.isCredentialEditMode && !_this.isWindowsScan(); };
        _this.displayWindowsCredentialComponent = function () { return _this.data.assessmentJob.auth && _this.isCredentialEditMode && _this.isWindowsScan(); };
        return _this;
    }
    AssessmentJobJobDetailsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setStepValidation(true);
        this.paris.apiCall(GetAuthenticatedScanAgentsList).subscribe(function (agents) {
            agents.map(function (agent) {
                _this.agentsList.push({ id: agent.id, machineName: agent.machineName });
                _this.agentNamesList.push(agent.machineName);
            });
        });
        this.isCredentialEditMode = this.data.isNewDraft || this.data.overrideAuth;
        // New scan, first time arriving to this step
        if (this.data.isNewDraft && !this.data.assessmentJob.agentId) {
            this.setStepValidation(false);
            this.data.assessmentJob.auth = this.isWindowsScan() ? new WindowsAuthParams() : new SnmpAuthParams();
        }
        this.targetChanged$.pipe(debounceTime(50)).subscribe(function () {
            _this.setStepValidation(_this.validAuthParams(_this.data.assessmentJob.auth) && _this.validTargetAddresses());
        });
        this.changeDetection.markForCheck();
    };
    AssessmentJobJobDetailsStepComponent.prototype.onAgentNameChange = function (agentName) {
        this.data.assessmentJob.agentId = this.getAgentIdByAgentName(agentName);
        this.data.requiredNewScan = true;
    };
    AssessmentJobJobDetailsStepComponent.prototype.editExistingAuth = function () {
        this.data.assessmentJob.auth = this.isWindowsScan() ? new WindowsAuthParams() : new SnmpAuthParams();
        this.isCredentialEditMode = true;
        this.data.overrideAuth = true;
        this.setStepValidation(false);
    };
    AssessmentJobJobDetailsStepComponent.prototype.reevaluateStepValidityForAuth = function (authParams) {
        this.data.requiredNewScan = true;
        this.setStepValidation(this.validAuthParams(authParams) && this.validTargetAddresses());
    };
    AssessmentJobJobDetailsStepComponent.prototype.getAgentIdByAgentName = function (name) {
        var agent = this.agentsList.filter(function (x) { return x.machineName === name; });
        return agent && agent[0].id;
    };
    AssessmentJobJobDetailsStepComponent.prototype.onAssessmentJobChange = function () {
        this.data.requiredNewScan = true;
        this.targetChanged$.next();
    };
    AssessmentJobJobDetailsStepComponent.prototype.validTargetAddresses = function () {
        var _this = this;
        var targetAddresses = this.data.assessmentJob.originalTargetRanges.split(/,\s*|\s+/);
        return targetAddresses.every(function (address) { return _this.RegExpService.ipv4OrRangeCidr.test(address); });
    };
    AssessmentJobJobDetailsStepComponent.prototype.validAuthParams = function (authParams) {
        if (!authParams) {
            return true; // we allow empty auth as when we don't edit existing auth, it is practically null
        }
        switch (authParams.$type) {
            case AuthenticationParamsSerializationType.SnmpAuthParams: {
                var scanAuthAsSnmp = authParams;
                return this.validSnmpAuth(scanAuthAsSnmp);
            }
            case AuthenticationParamsSerializationType.WindowsAuthParams: {
                var scanAuthAsWindows = authParams;
                return this.validWindowsAuth(scanAuthAsWindows);
            }
        }
    };
    AssessmentJobJobDetailsStepComponent.prototype.validSnmpAuth = function (snmpAuth) {
        // in case the form just init it will be marked as valid
        // validating out self that it has any value
        var formHasValues = !!snmpAuth.type && (snmpAuth.username != null || snmpAuth.communityString != null);
        return formHasValues && !this.snmpAuthTypeForm.form.invalid;
    };
    AssessmentJobJobDetailsStepComponent.prototype.validWindowsAuth = function (windowsAuth) {
        // in case the form just init it will be marked as valid
        // validating out self that it has any value
        var formHasValues = !!windowsAuth.type && !!windowsAuth.username && !!windowsAuth.password;
        var isDomainValid = !!windowsAuth.domain || (windowsAuth.type !== WindowsAuthType.DomainNtlm && windowsAuth.type !== WindowsAuthType.Kerberos);
        return formHasValues && isDomainValid && !this.windowsAuthTypeForm.form.invalid;
    };
    return AssessmentJobJobDetailsStepComponent;
}(WizardBaseStep));
export { AssessmentJobJobDetailsStepComponent };
