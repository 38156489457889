import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { SnmpAuthParams, SnmpAuthType, SnmpPrivProtocol, SnmpAuthProtocol } from '@wcd/domain';
import { FormControl, NgForm } from '@angular/forms';

@Component({
	selector: 'snmp-auth-type',
	templateUrl: './snmp-auth-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'snmpAuthType',
})
export class SnmpAuthTypeComponent {
	@Input() parentForm?: FormControl;
	@Input() label?: string;
	@Input() auth?: SnmpAuthParams = new SnmpAuthParams();
	@Output() authChange: EventEmitter<SnmpAuthParams> = new EventEmitter<SnmpAuthParams>();
	@ViewChild('snmpAuthTypeForm', { static: false }) public form: NgForm;

	SnmpAuthType = SnmpAuthType;

	connectionProtocolList = Object.values(SnmpAuthType);
	snmpAuthProtocolList = Object.values(SnmpAuthProtocol);
	snmpPrivProtocolList = Object.values(SnmpPrivProtocol);

	//clear input fields when auth type change
	updateSnmpType(snmpAuthType: SnmpAuthType) {
		this.auth = new SnmpAuthParams();
		this.auth.type = snmpAuthType;
		this.notifyAuthChanged();
	}

	notifyAuthChanged() {
		this.authChange.emit(this.auth);
	}
}
