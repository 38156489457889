import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

export enum AuthenticationParamsSerializationType {
	SnmpAuthParams = 'SnmpAuthParams',
	WindowsAuthParams = 'WindowsAuthParams',
}

@ValueObject({
	singularName: 'Authentication parameters',
	pluralName: 'Authentication parameters',
})
export class AuthenticationParams extends ModelBase {
	@EntityField()
	// this is type related metadata for BE use, and is not part of the core schema
	$type: AuthenticationParamsSerializationType;
}
