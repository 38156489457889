import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { TvmAuthenticatedScanSharedEntityConfigurations } from '../authenticatedScanEndPointUtil';
import { AuthenticationParams } from './authentication-params.value-object';

export enum AssessmentType {
	NetworkAssessment = 'Network',
	WindowsAssessment = 'Windows',
}

@Entity({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	singularName: 'Device authenticated scan',
	pluralName: 'Device authenticated scans',
	endpoint: 'scanDefinition',
})
export class AssessmentJob extends ModelBase {
	@EntityField({ required: true })
	scanName: string;

	@EntityField({ required: true })
	agentId: string;

	@EntityField()
	agentName: string;

	@EntityField()
	orgId: string;

	@EntityField({ required: true })
	target: string;

	@EntityField()
	originalTargetRanges: string;

	@EntityField()
	auth: AuthenticationParams;

	@EntityField({
		defaultValue: 'Pending',
		serialize: false,
	})
	lastScanStatus: string;

	@EntityField({
		serialize: false,
	})
	lastScanDate?: Date;

	@EntityField({
		parse: (date: string) => (date ? new Date(date).toLocaleString() : '-'),
		serialize: false,
	})
	lastScanTriggerDate: string;

	@EntityField()
	createdBy: string;

	@EntityField()
	active: boolean;

	@EntityField()
	scanType?: AssessmentType;
}
