<form ngForm="snmpAuthTypeForm" #snmpAuthTypeForm="ngForm">
       <section class="wcd-flex-vertical tvm-list">
              <div class="form-group tvm-list__block_item">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-snmp-auth-type">{{ label ? label : ('tvm.authenticatedScan.creationPanel.snmpAuth.type.label' | i18n) }}</label>
                     <div>
                            <fancy-select
                            [(ngModel)]="auth.type"
                            (ngModelChange)="updateSnmpType($event)"
                            id="authenticated-scan-snmp-auth-type"
                            name="snmpAuthType"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.type.placeholder' | i18n)"
                            [isFullWidth]="true"
                            [isBordered]="true"
                            [values]="connectionProtocolList">
                            </fancy-select>
                     </div>
              </div>
              <div class="tvm-list__block_item" *ngIf="auth.type === SnmpAuthType.CommunityString">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-community-string">{{'tvm.authenticatedScan.creationPanel.snmpAuth.communityString' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-community-string"
                            [(ngModel)]="auth.communityString"
                            (ngModelChange)="notifyAuthChanged()"
                            name="communityString"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.communityStringPlaceholder' | i18n)"
                            required/>
              </div>
              <div class="tvm-list__block_item" *ngIf="auth.type === SnmpAuthType.AuthNoPriv || auth.type === SnmpAuthType.AuthPriv || auth.type === SnmpAuthType.NoAuthNoPriv">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-username">{{'tvm.authenticatedScan.creationPanel.snmpAuth.username' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-username"
                            [(ngModel)]="auth.username"
                            (ngModelChange)="notifyAuthChanged()"
                            name="username"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.usernamePlaceholder' | i18n)"
                            required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="auth.type === SnmpAuthType.AuthNoPriv || auth.type === SnmpAuthType.AuthPriv">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-auth-protocol">{{'tvm.authenticatedScan.creationPanel.snmpAuth.authProtocol' | i18n}}</label>
                     <fancy-select
                     [(ngModel)]="auth.authProtocol"
                     id="authenticated-scan-snmp-auth-protocol"
                     name="snmpAuthProtocol"
                     [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.authProtocolPlaceholder' | i18n)"
                     [isBordered]="true"
					 [values]="snmpAuthProtocolList"
					 (ngModelChange)="notifyAuthChanged()"
                     labelClass="dropdown-width-medium"
                     required>
                     </fancy-select>
              </div>
              <div class="tvm-list__key_val_item" *ngIf="auth.type === SnmpAuthType.AuthNoPriv || auth.type === SnmpAuthType.AuthPriv">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-auth-password">{{'tvm.authenticatedScan.creationPanel.snmpAuth.authPassword' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-auth-password"
                            [(ngModel)]="auth.authPassword"
                            (ngModelChange)="notifyAuthChanged()"
                            name="authPassword"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.authPasswordPlaceholder' | i18n)"
                            type="password"
                            required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="auth.type === SnmpAuthType.AuthPriv">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-priv-protocol">{{'tvm.authenticatedScan.creationPanel.snmpAuth.privProtocol' | i18n}}</label>
                     <fancy-select
                     [(ngModel)]="auth.privProtocol"
                     id="authenticated-scan-snmp-priv-protocol"
                     name="snmpPrivProtocol"
                     [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.privProtocolPlaceholder' | i18n)"
					 [isBordered]="true"
					 (ngModelChange)="notifyAuthChanged()"
                     [values]="snmpPrivProtocolList"
                     labelClass="dropdown-width-medium"
                     required
                     [ariaLabel]="'tvm.authenticatedScan.creationPanel.snmpAuth.privProtocol' | i18n"
                     [dropdownAreaRole]="'combobox'">
                     </fancy-select>
              </div>
              <div class="tvm-list__key_val_item" *ngIf="auth.type === SnmpAuthType.AuthPriv">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-priv-password">{{'tvm.authenticatedScan.creationPanel.snmpAuth.privPassword' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-priv-password"
                            [(ngModel)]="auth.privPassword"
                            (ngModelChange)="notifyAuthChanged()"
                            name="privPassword"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.snmpAuth.privPasswordPlaceholder' | i18n)"
                            type="password"
                            required/>
              </div>
       </section>
</form>
