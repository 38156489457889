<!-- tslint:disable:template-accessibility-label-for -->
<h2 class="wcd-font-weight-bold">{{ 'tvm_authenticatedScan_wizard_review_step_title' | i18n }}</h2>

<div class="wcd-margin-large-top">
    <div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
        {{ data.isNewDraft ? ('tvm_authenticatedScan_wizard_create_review_description' | i18n) : 'tvm_authenticatedScan_wizard_update_review_description' | i18n }}
    </div>

    <table class="wcd-margin-medium-bottom">
        <label class="wcd-margin-large-top wcd-font-weight-semibold">
            {{ 'tvm_authenticatedScan_wizard_review_job_details' | i18n }}
        </label>
        <tr *ngIf="assessmentJobService.isWindowsScanFeatureEnabled()">
            <td class="wcd-padding-large-right">{{ 'tvm_authenticatedScan_wizard_review_assessment_type' | i18n }}</td>
            <td>{{ data.assessmentJob.scanType }}</td>
        </tr>
		<tr>
            <td class="wcd-padding-large-right">{{ 'tvm_authenticatedScan_wizard_review_assessment_name' | i18n }}</td>
            <td>{{ data.assessmentJob.scanName }}</td>
        </tr>
        <tr>
            <td class="wcd-padding-large-right">{{ 'tvm_authenticatedScan_wizard_review_assessment_device' | i18n }}</td>
            <td>{{ data.assessmentJob.agentName }}</td>
        </tr>
        <tr>
            <td class="wcd-padding-large-right">{{ 'tvm_authenticatedScan_wizard_review_target' | i18n }}</td>
            <td>{{ data.assessmentJob.originalTargetRanges }}</td>
        </tr>
        <tr *ngIf="data.assessmentJob.auth">
            <td class="wcd-padding-large-right">{{ 'tvm_authenticatedScan_wizard_review_authentication_protocol' | i18n }}</td>
            <td>{{ assessmentJobService.extractAuthTypeFromAssessment(data.assessmentJob) }}</td>
        </tr>
        <div class="wcd-padding-small-top">
            <a href="#" (click)="navigateToJobStep()">{{ 'tvm_authenticatedScan_wizard_review_edit' | i18n }}</a>
        </div>

        <label class="wcd-margin-large-top wcd-font-weight-semibold">
            {{ 'tvm_authenticatedScan_wizard_review_scan' | i18n }}
        </label>
        <tr>
            <td>{{ 'tvm_authenticatedScan_wizard_review_selected_device' | i18n }}</td>
            <td>{{ isAllSelected() ? ('common_all' | i18n) : data.selectedIps.length }}</td>
        </tr>
        <div class="wcd-padding-small-top">
            <a href="#" (click)="navigateToScanStep()">{{ 'tvm_authenticatedScan_wizard_review_edit' | i18n }}</a>
        </div>
    </table>
</div>
